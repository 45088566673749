import { graphql } from "gatsby";
import {
  LeadSection,
  Post,
  PostContentsContainer,
  Button,
  CardGrid,
  Card,
  PostContentsTitle,
  Title,
  Link,
} from "@lleed-partners/factor";

import { Icon } from "../fragments/Icon";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "../fragments/Card/fragments.tsx";
import {
  useI18next,
  Link as GatsbyLink,
  Helmet,
} from "gatsby-plugin-react-i18next";
import { ArrowRight32 } from "@carbon/icons-react";
import { mapElasticSearchResults } from "../graphql/helpers";
import { Layout } from ".";
import { Header } from "../fragments/Header";
import { ServiceCard } from "../fragments/Card/service";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { asBackground } from "../fragments/styles";

const IndustryLayout = ({
  data: {
    post: { frontmatter, body },
    relatedServices: rawRelatedServices,
  },
}) => {
  const relatedServices = mapElasticSearchResults(rawRelatedServices);

  const { t } = useI18next();

  let media = undefined;

  if (frontmatter.localHeaderImage) {
    media = (
      <GatsbyImage
        image={getImage(frontmatter.localHeaderImage)}
        style={asBackground}
        loading="eager"
      />
    );
  } else if (frontmatter.headerImage) {
    media = (
      <GatsbyImage
        image={getImage(frontmatter.headerImage)}
        style={asBackground}
        loading="eager"
      />
    );
  }

  return (
    <Layout navProps={{ showSubNav: false }}>
      <article>
        <Header frontmatter={frontmatter} />
        <Post>
          <MDXRenderer
            localImages={frontmatter.localImages}
            remoteImages={frontmatter.remoteImages}
          >
            {body}
          </MDXRenderer>
          <PostContentsContainer position="center-right-full">
            <LeadSection
              title={t("Get in touch")}
              subtitle={t(
                "Discuss the latest technologies in the {{industry}} field, and discover how we could help you leverage technology to take the lead.",
                {
                  industry: `${frontmatter.title}`.toLowerCase(),
                }
              )}
              inline
              intent="grey"
              backgroundMedia={media}
              cta={
                <Button
                  as={GatsbyLink}
                  rightIcon={<Icon icon={<ArrowRight32 />} />}
                  to="/contact/"
                >
                  {t("Contact us")}
                </Button>
              }
            />
          </PostContentsContainer>
          {relatedServices.edges.length ? (
            <>
              <PostContentsTitle
                title={<Title title={t("Related services")} />}
              />
              <PostContentsContainer position="center-full">
                <CardGrid intent="white" bordered>
                  {relatedServices.edges.slice(0, 3).map(({ node }) => (
                    <ServiceCard service={node} />
                  ))}
                </CardGrid>
              </PostContentsContainer>
            </>
          ) : null}
        </Post>
      </article>
    </Layout>
  );
};

export default IndustryLayout;

export const pageQuery = graphql`
  query ($id: String!, $language: String!, $suggestedQuery: JSON!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    post: mdx(id: { eq: $id }) {
      body
      frontmatter {
        category
        created
        modified
        description
        headerImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localHeaderImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        remoteImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        slug
        title
      }
    }

    relatedServices: elastic {
      search(body: $suggestedQuery)
    }
  }
`;
